$textFontFamily: 'Noto Sans';
$color: #424242;
$mb: 1023px;
$desk: 1024px;

*{
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: $textFontFamily;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: normal;
  color: $color;
}

body {
  overscroll-behavior: none;
  position: relative;
  background-color: #ffffff;
}

html {
  font-size: 0.69444vw;
}

.justify-wrap{
  text-align: justify;
  font-size: 0;
  text-justify: newspaper;
}

.justify-wrap:after{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus::-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-ms-input-placeholder {
  color: transparent !important;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.container{
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 320px;
  max-width: 100%;
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  margin-left: -2rem;
  margin-right: -2rem;
}

.col {
  padding-right: 2rem;
  padding-left: 2rem;
}

.wrapper{
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 320px;
  min-height: 100vh;
}

.hidden {
  display: none !important;
}

.anchor {
  position: absolute;
  bottom: 100%;
  height: 9rem;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;

  @media screen and (max-width: $mb){
    height: 5rem;
  }
}

svg {
  width: 100%;
  height: 100%;
  display: block;
  overflow: visible !important;
}

.anim__box {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  iframe {
    height: 99.99%;
    width: 99.99%;
    display: block;
    overflow:hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &:after{
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
  }
}

.sc__promo {
  //height: 100vh;
}

body.overflow {
  overflow: hidden;
}

.content {
  padding-bottom: 12.4rem;

  @media screen and (max-width: $mb){
    padding-bottom: 6.4rem;
  }
}

.sc__promo {
  //margin-bottom: -5.5rem;
}

.sc__development {
  @media screen and (max-width: $mb){
    background-color: #faf5ed;
    margin-bottom: -4rem;
    margin-top: 5rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      padding-bottom: 5%;
      width: 100%;
      background: no-repeat url("../img/creamy-transition.svg") bottom / 100% auto;
      pointer-events: none;
      z-index: 0;
    }
  }

  .container {
    padding-top: 6.4rem;
    padding-bottom: 8.2rem;

    @media screen and (max-width: $mb){
      padding-top: 5.3rem;
      padding-bottom: 15.7rem;
    }
  }
}

.promo__it {
  height: 62rem;
  position: relative;
  //background: #fff;

  @media screen and (max-width: $mb){
    height: auto;
  }

  &-ct {
    width: 52rem;
    height: 100%;
    position: relative;
    padding-left: 12rem;

    @media screen and (max-width: $mb){
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      text-align: center;
      padding-top: 3rem;
    }

    &-bg {
      width: 10rem;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;

      @media screen and (max-width: $mb){
        width: 100%;
        height: 4.4rem;
        bottom: -0.05rem;
        top: auto;
      }
    }

    &-bg2 {
      width: 100%;
      height: 5.4rem;
      position: absolute;
      left: 0;
      bottom: -0.2rem;
      z-index: 2;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 7.2rem;
    line-height: 6.4rem;
    font-family: 'Noteworthy';
    margin-bottom: 3rem;

    @media screen and (max-width: $mb){
      font-size: 3.6rem;
      line-height: .91429;
      margin-bottom: 2.4rem;
    }
  }

  &-descr {
    max-width: 35.5rem;
    font-size: 1.7rem;
    line-height: 2.5rem;

    @media screen and (max-width: $mb){
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  &-img {
    position: absolute;
    width: 91rem;
    right: 0;
    top: 0;
    height: 100%;

    @media screen and (max-width: $mb){
      position: relative;
      overflow: hidden;
      height: 30rem;
      width: 100%;

      &-wrap {
        margin-left: -3.3rem;
        margin-right: -3.3rem;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.sc__static {
  .container {
    padding-top: 14rem;
    padding-bottom: 15rem;
  }
  min-height: 100vh;
}

.page__title {
  font-family: 'Noteworthy';
  font-size: 5.4rem;
  line-height: 4.9rem;
  margin-bottom: 6.4rem;

  @media screen and (max-width: $mb){
    font-size: 3.6rem;
    line-height: 4.4rem;
    margin-bottom: 3.2rem;
  }
}

.seo__item {
  & > * {
    font: inherit !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.static__wrap {
  max-width: 106rem;
  margin-left: auto;
  margin-right: auto;
}

.text__body {
  font-size: 1.7rem;
  line-height: 2.5rem;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  p {
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;

    @media screen and (max-width: $mb){
      margin-top: 1.4rem;
      margin-bottom: 1.4rem;
    }
  }

  table {
    width: 100%;
    font-size: 1.6rem;
  }

  table, td, tr, th {
    border: 1px solid rgba(#000, 0.2);
    border-collapse: collapse;
  }

  thead {
    background-color: rgba(#000, 0.05);
  }

  td, th {
    padding: 0.6rem 0.8rem;
  }

  ol, ul {
    margin-left: 2rem;
  }

  h3 {
    font-size: 2.4rem;
    line-height: 3.6rem;
    letter-spacing: -.017rem;
    font-family: 'Noteworthy';
    margin-top: 4rem;

    @media screen and (max-width: $mb){
      font-size: 2rem;
      line-height: 3.2rem;
      margin-top: 3rem;
    }
  }

  u {
    text-decoration: underline !important;
  }

  a {
    color: #424242 !important;
    border-bottom: 1px solid rgba(#424242, 0.5);
    will-change: border-color;
    transition: border-color 0.2s ease;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.logo__2 {
  width: 27.7rem;
  margin-right: 3rem;
  margin-left: -10rem;

  @media screen and (max-width: $mb){
    width: 17.1rem;
    margin-right: auto;
    margin-left: auto;
  }

  img {
    display: block;
    width: 100%;
  }
}

.development__title {
  font-family: 'Firenight';
  color: #E73F0C;
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1.4rem;
  line-height: normal;

  @media screen and (max-width: $mb){
    font-size: 3.2rem;
    width: 100%;
    text-align: center;
    margin-bottom: 4.2rem;
  }
}

.form__label {
  color: #000;
  font-family: 'Circe';
  font-size: 1.4rem;
  font-weight: 400;

  span {
    color: #E8451B;
  }
}

.form__it {
  &:not(:last-child){
    margin-bottom: 1.2rem;
  }
}

.form__field {
  input {
    height: 4.4rem;
    padding: 0 1.4rem;
    width: 100%;
    display: block;
    border-radius: 0.8rem;
    font-size: 1.5rem;
    color: #000;
    background: rgba(131, 208, 245, 0.40);
    border: 1px solid transparent;
  }
}

.form__it--phone {
  .form__field {
    max-width: 17rem;
  }
}

.checkbox__decor {
  width: 2.2rem;
  height: 2.2rem;
  min-width: 2.2rem;
  margin-right: 1.2rem;
  border-radius: 0.4rem;
  position: relative;
  cursor: pointer;
  background: #E8451B;

  &:after {
    content: '';
    position: absolute;
    background: url('../img/checked.svg') no-repeat center;
    background-size: contain;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.2s ease;
  }
}

.checkbox__title {
  color: #000;
  font-size: 1.4rem;
  line-height: 110%;
  margin-top: 0.4rem;

  a {
    color: #009FE5;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}

.modal__form {
  position: relative;
}

.form--loading {
  position: relative;

  .form__group, & > .form__field, & > .form__list, .modal__action {
    opacity: 0.2;
  }

  .modal__action {
    &:after {
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 4;
    }
  }

  &:before{
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 8;
    position: absolute;
  }

  &:after {
    content: '';
    background: url("../img/loader.svg") no-repeat center;
    background-size: contain;
    width: 9.6rem;
    height: 9.6rem;
    left: 50%;
    top: 50%;
    position: absolute;
    margin-left: -4.8rem;
    margin-top: -4.8rem;
    z-index: 10;
  }
}

.modal__form {
  .modal__action {
    margin-top: 4.4rem;
  }
}

.checkbox__wrap {
  label {
    font-weight: normal;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;

    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;

      &:checked {
        & ~ .checkbox__decor {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.has-error {
  input {
    border-color: #E8451B;
  }
}

.fader {
  height: 62rem;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $mb){
    height: 64rem;
  }
}

.fader__slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.sc__promo {
  position: relative;
}

.carousel-button {
  position: absolute;
  top: 50%;
  width: 6.4rem;
  height: 5.2rem;
  cursor: pointer;
  transform: translateY(-50%);
  opacity: 1;
  z-index: 5;

  @media screen and (min-width: $desk){
    &:hover {
      animation: carouselButtonBounce 0.5s;
    }

    transition-timing-function: cubic-bezier(0.47,2.02,0.31,-0.36);
  }

  @media screen and (max-width: $mb){
    top: 13.35rem;
    height: 82px;
    width: 35px;
  }

  &-prev {
    left: 1rem;

    @media screen and (max-width: $mb){
      left: 0;
      background: url("../img/sl-prev-mb.svg") no-repeat center;
      background-size: contain;
    }
  }

  &-next {
    right: 1rem;

    @media screen and (max-width: $mb){
      right: 0;
      background: url("../img/sl-next-mb.svg") no-repeat center;
      background-size: contain;
    }
  }

  svg {
    display: block;
    width: 6.3rem;
    height: 6.3rem;
  }
}

.promo__it-action {
  margin-top: 3.4rem;

  @media screen and (max-width: $mb){
    margin-top: 2.8rem;
    justify-content: center;
  }
}

.fader__pagination {
  position: absolute;
  bottom: -4rem;
  left: 0;
  width: 100%;
  z-index: 5;

  @media screen and (max-width: $mb){
    bottom: auto;
    top: 29.9rem;
  }

  &-dot {
    padding: 0.8rem;
    transition: transform .25s;
    will-change: transform;
    transform: scale(0.5);
    cursor: pointer;
    border: none;
    background: transparent;

    @media screen and (max-width: $mb){
      padding: 4px;
    }

    &.active {
      transform: scale(1);

      &:after {
        background-color: #009FE5;
      }
    }

    &:after {
      width: 1.6rem;
      height: 1.6rem;
      display: block;
      content: '';
      border-radius: 50%;
      background-color: #351917;

      @media screen and (max-width: $mb){
        width: 8px;
        height: 8px;
      }
    }
  }
}

.promo__it-title--over {
  position: relative;
  z-index: 5;
}

.btn__promo {
  display: inline-block;
  position: relative;
  border: 0;
  border-radius: 9rem;
  padding: 1.9rem 5rem 1.5rem;
  text-decoration: none;
  text-align: center;
  color: #fff !important;
  cursor: pointer;
  transition: all .5s ease;
  z-index: 1;
  background: #f3410e;
  box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.1);
  font-size: 2.88rem;
  font-family: 'Noteworthy';
  line-height: 0.71429;
  font-weight: bold;

  @media screen and (max-width: $mb){
    padding: 1.2rem 4rem 1rem;
    font-size: 2.4rem;
    line-height: .83333;
  }

  &:hover {
    background-color: #db3a0d;
    transition: all .5s ease;
  }
}


@media screen and (min-width: $desk){
  .promo__it--3 {
    .promo__it-title {
      font-size: 5.8rem;
      line-height: 6.2rem;
      margin-right: -9rem;
    }
  }
}

@keyframes carouselButtonBounce {
  0% {
    transform: scale(1) translateY(-50%);
  }

  25% {
    transform: scaleX(1.2) scaleY(0.8) translateY(-50%);
  }

  50% {
    transform: scaleX(0.8) scaleY(1.2) translateY(-50%);
  }

  75% {
    transform: scaleX(1.1) scaleY(1.1) translateY(-50%);
  }
}

@media screen and (min-width: $desk){
  .mb-only {
    display: none !important;
  }
}

@media screen and (max-width: $mb){
  html {
    font-size: 2.6667vw;
  }

  .desk-only {
    display: none !important;
  }

  .development__ct {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .promo__it--2 {
    .promo__it-img-wrap {
      margin-left: -4rem;
      margin-right: 0;
      margin-top: -1rem;
    }
  }
}