@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Light.woff2') format('woff2'),
        url('NotoSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Medium.woff2') format('woff2'),
        url('NotoSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Regular.woff2') format('woff2'),
        url('NotoSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noteworthy';
    src: url('Noteworthy-Bold.woff2') format('woff2'),
        url('Noteworthy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noteworthy';
    src: url('Noteworthy-Light.woff2') format('woff2'),
    url('Noteworthy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Firenight';
    src: url('Firenight-Regular.woff2') format('woff2'),
    url('Firenight-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circe';
    src: local('Circe Regular'), local('Circe-Regular'),
    url('Circe-Regular.woff2') format('woff2'),
    url('Circe-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

